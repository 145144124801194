import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import * as localForageGlobal from 'localforage';
import { ALL_TASQS_LIST_ITEM, SHOW_ALERT, SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
// import { TASQS_LISTS,  } from '@/lib/constants';
import isOnline from 'is-online';
import localForage from '@/lib/localForage';
import assetsModule from '@/store/modules/assetsModule';
import workflowModule from '@/store/modules/workflowModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import proceduresModule from '@/store/modules/proceduresModule';
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from '@/lib/auth';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import metaDataModule from '@/store/modules/metaDataModule';
import scheduleModule from '@/store/modules/scheduleModule';

async function preloadData(vueThis) {
  // console.log(vueThis)

  Chart.plugins.unregister(ChartDataLabels);
  if (navigator.onLine && !tasqsListModule.savingForOffline) {
    // localForage.clear();
    // localForageGlobal.clear();
    tasqsListModule.setsaveOffline(true);

    vueThis.$router.push({ name: 'Tasqs' });

    await sleep(3000);








    // let vue = new Vue()
    if (!accountModule.user || !accountModule.user.email) {
      return;
    }

    if (!accountModule.user || !accountModule.user.email) {
      return;
    }

    //   await accountModule.getReassignmentList();
    // await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });
    // const enablePreloading = !!(workflowModule.user != null && workflowModule.user.role != null && (workflowModule.user.role == 'Operators' || workflowModule.user.role == 'FieldOperator'));
	  // const myUser = await accountModule.user;
    let alreadyPreLoaded = accountModule.didPreloadData
    if (!alreadyPreLoaded) {

      vueThis.$router.push({ name: 'FormMobileSubmission' });



      await sleep(2000);

      await sleep(3000);


      // tasqsListModule.addProgresPercentage(10);

      const promises: any[] = [];

      tasqsListModule.setEablePreloading(true);
      promises.push(preloadingActions(vueThis))
      promises.push(tasqsListModule.getTasqs({
        text: 'All tasqs',
        val: 'ALL',
      }))
      promises.push(tasqsListModule.getSubmittedForms())
      promises.push(workflowModule.listTeams({ useExisting: true }));
      promises.push(scheduleModule.listAreas({ useExisting: true }));
      promises.push(scheduleModule.getEnabledRoutes());
      promises.push(tasqsListModule.getWaitList());
      // promises.push(tasqsListModule.getTasqListResponses());
      promises.push(assetsModule.getEnabledWells());
      promises.push(accountModule.getReassignmentList({ useExisting: true }));
      promises.push(await tasqsListModule.getWaitList());
      // promises.push()

      Promise.all(promises).then(async () => {

        accountModule.setDidPreloadData(true);


        // const result: any = await localForage.getItem('saveOfflineTodayTasqList');
        // const counter = (80 / result.length);
        const wellNames: any = [];

        vueThis.$router.push({ name: 'UserProfile' });
        // if (result && result.length) {
        //   accountModule.setDidPreloadData(true);
        //   // eslint-disable-next-line no-plusplus
        //   for (let i = 0; i < result.length; i++) {
        //     // console.log((result[i].wellName));
        //     if (!wellNames.includes(result[i].wellName)) {
        //       // await preloadingTasqCharts(result[i]);
        //       // await (tasqProductionDataChartModule.getWellHistory({ daysLength: 5, wellName: result[i].wellName }));
        //       // await (workflowModule.getWellHistory({ wellName: result[i].wellName }));
        //       wellNames.push(result[i].wellName);
        //       localForageGlobal.clear();
        //     }
        //     // await setWaitingOnOptions()
        //     // console.log(i);
			  // let resetCounter = counter;
			  // if (counter > 100) {
        //       resetCounter = 100;
			  // }

        //     tasqsListModule.setProgresPercentage(resetCounter);
        //     localForageGlobal.clear();
        //   }
        //   // tasqsListModule.setActiveTasq('');
        // } else {
        //   tasqsListModule.addProgresPercentage(0);
        // }


        setTimeout(() => {
          vueThis.$router.push({ name: 'Tasqs' });
          tasqsListModule.setsaveOffline(false);
          // tasqsListModule.setsaveOffline(false);
        }, 1500)
        setTimeout(() => {
          // vueThis.$router.push({ name: 'Tasqs' });

        }, 15000)

      }, (err) => {
        console.log(err);
         vueThis.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, { text: 'It is not possible to preload data at this moment, please try later', error: true });

        // error occurred
        tasqsListModule.setsaveOffline(false);
      });


    }
  }
}

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// async function preloadingTasqCharts(result) {
//   try {
//     // console.log(result.engineerType)
//     if (result.engineerType === 'Setpoint') {
//       // await setpointV2Module.getSetpointRecV2ForTasq({ wellName: result.wellName, isOfflinePreloading: true });
//     }
//     await tasqSignalsModule.getSignalsForTasq({ daysLength: 5, wellName: result.wellName });
//     await (tasqProductionDataChartModule.getProductionData({ daysLength: 5, wellName: result.wellName }));
//     // await (defermentLabelingModule.getChartLabelingData({signalChartTime: 5, wellName: result.wellName}));
//   } catch (error) {
//     console.log(`Something went wrong on signals offline storage ${result.wellName}`);
//   }
// }



// async function setWaitingOnOptions() {
//   await metaDataModule.getDatalist();
//   const requiredDataList = metaDataModule.requiredDatalist;
//   const formCategoriesItem = requiredDataList.find((list) => list.title === 'Waiting On');
//   // const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
//   if (formCategoriesItem) {
//     const formCategoriesPayload = await metaDataModule.getDatalist(formCategoriesItem.id);
//     if (formCategoriesPayload && formCategoriesPayload.length) {
//       const waitingOnOptions = formCategoriesPayload[0].items.filter((field) => field.active).map((item) => item.Name).sort();
//       if (waitingOnOptions) {
//         metaDataModule.setWaitingOnList(waitingOnOptions);
//       }
//     }
//   }
// }

async function preloadingActions(vueThis) {
  try {
    // console.error('I m preloading');
    // await (workflowModule.getJobResponse({ id: result.id }));
    const actions: any = await metaDataModule.getActionsBuilder();


  } catch (error) {
    console.log('Something went wrong on Action offline storage');
  }
}

export {
  preloadData,
};
